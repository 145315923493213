<template>
  <div>
    <h3>{{ pageTitle }}</h3>
    <competitions-list
        :items="competitions"
        :canDelete="userHasRole(Roles.Admin)"
        :confirmBeforeDeleting="true"
        :canEdit="userHasRole(Roles.Admin)"
        @select="selectCompetition"
        @remove="deleteCompetition"
        @edit="editCompetition"
    >
    </competitions-list>
  </div>
</template>

<script>
import CompetitionsList from '@/components/CompetitionsResultsList'
import ResultsHelper from '@/services/helpers/resultsHelper';
import { REF_COMPETITION_TYPE } from '@/LocalDB/index.js';

export default {
    data(){
      return {
        competitionTypeCode: null,
        competitions: [],
      }
    },
    components: { CompetitionsList },
    computed:{
        competitionType(){
            var ret = REF_COMPETITION_TYPE.query().where("code", this.competitionTypeCode).first();
            console.log("competitionType", ret);
            return ret;
        },
        pageTitle(){
          if(this.competitionType != null)
          {
            var ret = this.competitionType.longname;
            return ret;
          }
          return null;
        },
    },
    methods:{
        async loadAndRefresh(){
          this.competitions = await ResultsHelper.getCompetitionsResultsList(this.competitionTypeCode, this.isTestMode)
        },
        selectCompetition(competition)
        {
          this.$showLoader()
            .then(() => {
                this.$router.push({ name: 'EditCompetitionResults', params: { id: competition.id }});
            });
        },
        editCompetition(competition){
          this.$showLoader()
            .then(() => {
              this.$router.push({ name:'EditCompetitionResults', params: { id: competition.id }});
            })
        },
        deleteCompetition(competition)
        {
          this.$showLoader()
            .then(() => {
              ResultsHelper.deleteCompetition(competition.id);
              this.$hideLoader();
          });
        }
    },
    async mounted(){
      this.$hideLoader();
      this.competitionTypeCode = this.$route.params["competitionType"];
      this.loadAndRefresh();
    },
    watch: {
        $route(to, from) {
            if (to !== from) {
                console.log("ListCompetitionsResults.vue > route changed : ", to);
                this.competitionTypeCode = to.params["competitionType"];
                this.loadAndRefresh();
            }
        },
    },
}
</script>

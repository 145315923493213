import axios from "axios";
import { store } from "@/services/store";
import { RolesEnum } from "@/Constants";

const ResultsHelper = {

  async getCompetitionsResultsList(competitionType_Code, forTestMode)
  {
    if(forTestMode == null)
    {
      forTestMode = store.getters["auth/userHasRole"]( RolesEnum.Tester );
    }
    var baseUrl = store.getters["config/serverBaseUrl"];
    var url = baseUrl + '/api/getCompetitionsResultsList.php';
    try{
      const response = await axios.post(url, { testMode: forTestMode, competitionType: competitionType_Code });
      console.log('getCompetitionsResults =>', response.data);
      return response.data.competitionsList;
    }
    catch(error){
      console.log('getCompetitionsResults ERROR => ' + error);
    }
  },

  async getCompetitionResults(competition_id){
    var baseUrl = store.getters["config/serverBaseUrl"];
    var url = baseUrl + '/api/getCompetitionResults.php';
    try{
      const response = await axios.post(url, { id: competition_id });
      console.log('getCompetitionResults =>', response.data);
      return response.data.competition;
    }
    catch(error){
      console.log('getCompetitionResults ERROR => ' + error);
    }
  },

  async deleteCompetition(competition_id){
    console.log("deleteCompetition (not implemented)", competition_id);
    throw "Not implemented";
  },

}

export default ResultsHelper;

<template>
  <div>
    <collapsable-season-title
      v-for="year in seasons"
      :key="year"
      :year="year"
      :collapsed="year == new Date().getYear()"
    >
<secured-area :roles="[Roles.Admin]">
  <template #others>
        <ul>
          <li v-for="competition in seasonCompetitions(year)" :key="competition.id">
            {{ competition.name }}
          </li>
        </ul>
  </template>
      <b-table small hover :items="seasonCompetitions(year)" :fields="columns" class="thisTable" responsive>
        <template #cell(selector)="data">
            <b-button size="sm" :disabled="data.item.cannotBeSelected" @click="select(data.item)">Select.</b-button>
        </template>
        <template #cell(name)="data">
            <span class="name">{{ data.item.name}}</span>
        </template>
        <template #cell(year)="data">
            <span class="year">{{ data.item.year}}</span>
        </template>
        <template #cell(competitionType)="data">
            <span class="competitionType">{{ data.item.competitionType.shortname}}</span>
        </template>
        <template #cell(location)="data">
            <span class="location">{{ data.item.location}}</span>
        </template>
        <template #cell(scope)="data">
            <span class="scope">{{ data.item.scope.shortname}}</span>
        </template>
        <template #cell(isOpen) = "data">
            <b-checkbox size="sm" v-model = "data.item.isOpen" :disabled = "true"></b-checkbox>
        </template>
        <template #cell(isSimulation) = "data">
            <b-checkbox size="sm" v-model = "data.item.isSimulation" :disabled = "true"></b-checkbox>
        </template>
        <template #cell(editor)="data">
          <b-button size="sm" :disabled="data.item.cannotBeEdited" variant="warning" @click="edit(data.item)">Editer</b-button>
        </template>
        <template #cell(deletor)="data">
            <b-button size="sm" :disabled="data.item.cannotBeDeleted" variant="danger" @click="confirmDeletion(data.item)" v-if="!data.item.isStarted">Suppr.</b-button>
        </template>
    </b-table>
</secured-area>
    </collapsable-season-title>

    <b-modal
      v-model="showDeletionConfirmationDialog"
      title="Confirmation"
      @ok="remove(competitionToDelete)"
      @cancel="competitionToDelete=null"
      ok-variant="warning"
      ok-title="Oui, supprimer"
      cancel-variant="secondary"
      cancel-title="Non"
    >
      <p>Etes-vous sûr de vouloir supprimer cette compétition ?</p>
    </b-modal>
  </div>
</template>

<script>
import { REF_COMPETITION_TYPE, REF_SCOPE, DATA_SYNC_SUBSCRIPTIONS } from "@/LocalDB"
import CollapsableSeasonTitle from './CollapsableSeasonTitle.vue';
import SecuredArea from '@/components/Security/SecuredArea.vue';

export default {
  components: { CollapsableSeasonTitle, SecuredArea },
    props:{
        items: { type: Array},
        showName: {type: Boolean, default: true },
        showLocation: {type: Boolean, default: false },
        showYear: { type:Boolean, default: false },
        showCompetitionType: { type: Boolean, default: false },
        showScope: { type: Boolean, default: false },
        showIsOpen: { type: Boolean, default: false},
        showIsSimulation: { type: Boolean, default: false},
        canEdit : { type: Boolean, default: false },
        canDelete: {type:Boolean, default:false},
    },
    data(){
      return {
        showDeletionConfirmationDialog: false,
        competitionToDelete: null,
      }
    },
    computed:{
      seasons(){
        return [...new Set(this.items.map(item => item.year))].sort((a,b) => a-b);
      },
        columns() {
            var ret = [];

            ret.push({key: 'selector', sortable: false, label: '', class:"selector", stickyColumn: true});
            if(this.canLock || this.canUnlock)
              ret.push({ key:"lock", sortable: 'false', label: '', class:'', stickyColumn: true });
            if(this.showName)
                ret.push({key: 'name', sortable:true, label: 'Nom', class:"name", stickyColumn: true});
            if(this.showLocation)
                ret.push({key: 'location', sortable:true, label:"Lieu", class:"location", stickyColumn: true});
            if(this.showYear)
                ret.push({key: 'year', sortable: true, label:'Saison', class:'year', stickyColumn: true});
            if(this.showCompetitionType)
                ret.push({key: 'competitionType', sortable: true, label: 'Type', class:'competitionType', stickyColumn: true});
            if(this.showScope)
                ret.push({ key: 'scope', sortable: true, label: 'Echelon', class: 'scope'});
            if(this.showIsOpen)
                ret.push({ key: 'isOpen', sortable: true, label: 'Est open', class: 'isOpen'});
            if(this.showIsRunning)
                ret.push({key: 'isRunning', sortable: true, label: 'En cours...', class:'isRunning'});
            if(this.showIsStarted)
                ret.push({ key: 'isStarted', sortable: true, label: 'Est commencé', class: 'isStarted'});
            if(this.showIsCompleted)
                ret.push({ key: 'isCompleted', sortable: true, label: 'Est terminé', class: 'isCompleted'});
            if(this.showIsSimulation)
                ret.push({key: 'isSimulation', sortable:true, label: 'Est simulation', class: 'isSimulation'});
            if(this.canEdit)
                ret.push({key: 'editor', sortable:false, label: '', class: 'editor'});
            if(this.canDelete)
                ret.push({ key: 'deletor', sortable:false, label: '', class:'deletor'});
            return ret;
        },
        itemsList(){
            var ret = [];

            if(this.items != null){
                ret = this.items.map(element => {
                    var isSynchronized = DATA_SYNC_SUBSCRIPTIONS.query().where("type", 'RST_COMPETITION').where("id", element.id).exists();
                    var mapped = {
                        id: element.id,
                        name: element.name,
                        year: element.year,
                        location: element.location,
                        isOpen: element.isOpen,
                        isStarted: element.isStarted,
                        isCompleted: element.isCompleted,
                        isRunning: element.isStarted && !element.isCompleted,
                        isSimulation: element.isForSimulationPurposeOnly,
                        isLinked: isSynchronized,
                        isLockedForChanges: element.isLockedForChanges,
                    };
                    if(this.showCompetitionType){
                        mapped.showCompetitionType = REF_COMPETITION_TYPE.query().where('code', element.competition_type);
                    }
                    if(this.showScope){
                        mapped.scope = REF_SCOPE.query().where('code', element.scope)
                    }
                    return mapped;
                });
            }
            return ret;
        }
    },
    methods:{
      seasonCompetitions(year){
        return this.items.filter(c => c.year == year);//.sort((a,b) => a.fromDate - b.fromDate);
      },
        select(competition){
            this.$emit('select', competition)
        },
        edit(competition){
          this.$emit('edit', competition);
        },
        confirmDeletion(competition){
          this.competitionToDelete = competition;
          this.showDeletionConfirmationDialog = true;
        },
        remove(competition){
            this.$emit('remove', competition)
        },
   }
}
</script>

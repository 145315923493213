<template>
  <div>
    <h4
      :class="isVisible ? null : 'collapsed'"
      :aria-expanded="isVisible ? 'true' : 'false'"
      aria-controls="collapsePanel"
      @click="isVisible= !isVisible"
    >
      <slot name="button-text">
        {{ year }}
      </slot>
    </h4>
    <b-collapse id="collapsePanel" v-model="isVisible">
      <slot></slot>
    </b-collapse>
  </div>
</template>

<script>

export default{
  props: { year : { type: Number, required:true }, collapsed : { type: Boolean, default: true} },
  data(){
    return {
      isVisible: !this.collapsed,
    }
  }
}
</script>
